import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, setUser } from "../../features/slices/HomeSlice";
import { useNavigate } from "react-router-dom";
import "./Header.scss";
import notice from "../../assets/files/PrivacyNoticeESGSuite3.0.pdf";
import MenuIcon from "@mui/icons-material/Menu";
import {
  MotifButton,
  MotifAvatar,
  MotifDropdownItem,
  MotifDropdown,
} from "@ey-xd/motif-react";
import { PrivacyNote, HelpDesk } from "../../constants/icons/Icons";
import EYHeaderLogo from "../../assets/images/HeaderLogo.png";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const languageOptions = [
  { value: "en", label: "EN" },
  { value: "es", label: "ES" },
];

const Header = () => {
  const { t, i18n } = useTranslation();
  const userState = useSelector((state) => state.user);
  const name = userState.user?.name;
  const photo = userState.photo;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  useEffect(() => {
    if (userState.user) {
      const storedUserData = localStorage.getItem("user");
      if (storedUserData) {
        const userData = JSON.parse(storedUserData);
        dispatch(setUser(userData));
      }
      const storedLanguage = localStorage.getItem("language");
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      } else {
        const preferredLanguage = "en";
        i18n.changeLanguage(preferredLanguage);
        localStorage.setItem("language", preferredLanguage);
      }
    }
  }, [dispatch, i18n, userState.user]);

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      const domainParts = window.location.hostname.split(".");
      const domain =
        domainParts.length > 1
          ? domainParts.slice(-2).join(".")
          : window.location.hostname;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domain}`;
    }
  };

  const handleSignOut = async () => {
    try {
      dispatch(logoutUser());
      localStorage.clear();
      sessionStorage.clear();
      deleteAllCookies();
      if (window.msalInstance) {
        console.log("MSAL instance found, logging out");
        await window.msalInstance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      } else {
        console.warn("MSAL instance not found. Redirecting to home.");
        navigate("/");
      }
    } catch (error) {
      console.error("Error during sign-out", error);
    }
  };

  const handleLanguageChange = (selectedOption) => {
    const language = selectedOption.value;
    i18n.changeLanguage(language);
    sessionStorage.setItem("language", language);
  };

  return (
    <header className="header-container">
     <div>
        <a href="/" aria-label="Home" className="logo-title">
          <img src={EYHeaderLogo} alt="Logo" width="40" height="40" />
          <h1 className="brand-title">{t("Title")}</h1>
        </a>
      </div>
      <nav className="nav-items">
        <ul>
          <li>
            <MotifButton
              className="header-motif-body"
              onClick={() => window.open(notice, "_blank")}
              data-testid="privacy-note-button"
            >
              <PrivacyNote />
              {t("PrivacyNote")}
            </MotifButton>
          </li>
          <li>
            <MotifButton className="header-motif-body" data-testid="help-desk-button">
              <HelpDesk />
              {t("HelpDesk")}
            </MotifButton>
          </li>
        </ul>
      </nav>
      <div className="right-menu">
        <button
          className="burger-menu"
          onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
          data-testid="menu-icon"
          aria-label="Toggle mobile menu"
        >
          <MenuIcon size={30} />
        </button>
        {userState.user && (
          <MotifDropdown
            ariaLabelledby="dropdown-trigger-1"
            closeOnOutsideClick={false}
            id="dropdown-1"
            placement="bottom-start"
            trigger={
              <div>
                <MotifAvatar
                  className="avatar-portal"
                  description={name}
                  size="medium"
                  userName={name}
                  src={photo ? `data:image/png;base64,${photo}` : undefined}
                  onError={(e) => (e.target.src = undefined)}
                  onClick={() => setDropdownVisible(!dropdownVisible)}
                  data-testid="user-avatar"
                />
              </div>
            }
          >
            <div className="header-dropdown-menu">
              <ul aria-label="profile menu">
                <li>
                  <MotifDropdownItem>
                    <Select
                      aria-labelledby="select-label"
                      className="header-select"
                      onChange={handleLanguageChange}
                      value={languageOptions.find(
                        (option) => option.value === i18n.language
                      )}
                      options={languageOptions}
                    />
                  </MotifDropdownItem>
                </li>
                <span className="motif-dropdown-horizontal-divider" />
                <li>
                  <MotifButton
                    className="header-motif-body"
                    onClick={handleSignOut}
                    data-testid="sign-out-button"

                  >
                    {t("Log out")}
                  </MotifButton>
                </li>
              </ul>
            </div>
          </MotifDropdown>
        )}
      </div>
      {mobileMenuVisible && (
        <div className="mobile-menu show">
          <ul>
            <li>
              <MotifButton
                className="header-motif-body"
                onClick={() => window.open(notice, "_blank")}
                data-testid="mobile-privacy-note-button"
              >
                <PrivacyNote />
                {t("PrivacyNote")}
              </MotifButton>
            </li>
            <li>
              <MotifButton
                className="header-motif-body"
                data-testid="mobile-help-desk-button"
              >
                <HelpDesk />
                {t("HelpDesk")}
              </MotifButton>
            </li>
            {userState.user && (
              <li>
                <MotifButton
                  className="header-motif-body"
                  onClick={handleSignOut}
                  data-testid="mobile-sign-out-button"
                >
                  {t("Log out")}
                </MotifButton>
              </li>
            )}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
