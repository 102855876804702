import React from "react";
import "./SubHeader.scss";
import { MotifButton } from "@ey-xd/motif-react";
import { Add } from "../../constants/icons/Icons";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Subheader = ({
  title,
  onChangeSearchInput,
  addButtonText,
  onClickNewProject,
  disableButton,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <div id="subHeader">
        <h1 className="Text">{title}</h1>
        <div className="subheader-button-container">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: isMobile ? "100%" : 400,
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              onChange={(event) => onChangeSearchInput(event.target.value)}
              inputProps={{ "aria-label": "search For" }}
            />
          </Paper>
          <div className="subheader-buttons">
            <MotifButton
              className="subheader-button-add"
              onClick={onClickNewProject}
              size="small"
              type="submit"
              variant="primary-alt"
              disabled={disableButton}
            >
              {addButtonText}
              <Add />
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

Subheader.propTypes = {
  title: PropTypes.string.isRequired,
  onChangeSearchInput: PropTypes.func.isRequired,
  addButtonText: PropTypes.string.isRequired,
  onClickNewProject: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

export default Subheader;
