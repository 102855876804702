import React, { useState, useEffect } from "react";
import Header from "../../components/headers/Header";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import Disclaimer from "./Disclaimer";
import LandingPage from "./LandingPage";
import "./Home.scss";

const Home = () => {
  const [showModal, setShowModal] = useState(true);
  const userState = useSelector((state) => state.user);
  const { inProgress } = useMsal();

  const handleAcknowledge = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!userState.user && inProgress === "none") {
      setShowModal(true);
    } else if (inProgress === "login") {
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  }, [userState.user, inProgress]);

  return (
    <div className="homePage-container">
      <Header />
      {showModal && !userState.user && (
        <Disclaimer onAgree={handleAcknowledge} />
      )}
      <LandingPage />
    </div>
  );
};

export default Home;
